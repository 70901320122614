.bnews__slider {
  max-height: 78px; // to avoid seing all the list at page load
  overflow: hidden;

  .bnews-content {
    &.icons {
      width: calc(100% - 35px); } } }



/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .bnews__slider {
    margin-top: 14px; } }



// SM, MD and LG
//@media screen and (min-width: $min-sm )
////.bnews__slider


// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm) {
  .bnews__slider {
    max-height: 85px; } }

// MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .bnews__slider {
    max-height: 40px; } }

// LG
@media screen and (min-width: $min-lg ) {
  .bnews__slider {
    max-height: 48px; } }
