.bnews {
  min-height: 155px;

  a {
    overflow: hidden;
    width: 100%; } // IE11

  .teaser__date {
    color: $dark-grey;
    display: block;
    font-size: 1.1rem;
    font-weight: $semi-bold;
    line-height: 1.2;
    margin-bottom: 4px;
    margin-right: 10px; }

  .teaser__headline {
    font-size: 1.8rem; }

  .bnews__hour {
    display: none; } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .bnews {
    .teaser__date {
      display: block; }

    .teaser__kicker {
      display: block; }

    .teaser__kicker,
    .teaser__headline {
      max-height: 40px; } } }

// < 420
@media screen and (max-width: 420px) {
  .bnews {
    min-height: 175px; } }

// XS and SM
//@media screen and (max-width: $max-sm)
////.bnews

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .bnews {
    padding-bottom: 17px;
    width: 100%;

    .title {
      line-height: 1;
      margin-bottom: 15px; } } }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .bnews {
    min-height: 160px;

    .teaser__date {
      font-weight: $bold; } } }

// MD and LG
@media screen and (min-width: $min-md) {
  .bnews {
    .bnews-content {
      &.icons {
        a {
          display: block; } } }

    .teaser__kicker,
    .teaser__headline {
      display: inline; }

    .teaser__date {
      align-items: center;
      display: block; }

    a {
      display: block;
      @include ellipsis(); } } }


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .bnews {
    min-height: 135px; } }


// LG
@media screen and (min-width: $min-lg) {
  .bnews {
    min-height: 145px;

    .teaser__date {
      font-size: 1.3rem; }

    .teaser__headline {
      font-size: 2.2rem; } } }

