.article__tags {
  margin-top: 30px;

  .title {
    margin-bottom: 0; }

  ul {
    li {
      display: inline-block;
      margin: 16px 10px 0 0;

      a {
        align-items: center;
        background: $lightest-grey;
        border-radius: 5px;
        color: $dark-grey;
        display: inline-flex;
        font-size: 1.3rem;
        font-weight: bold;
        height: 32px;
        padding: 0 10px;

        &:hover {
          background: $lighter-grey;
          color: $darker-grey; } } } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__tags


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__tags {
    @include border(t);
    @include border(b);
    padding: 20px 0;

    .title {
      margin-bottom: 0; } } }



// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__tags


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__tags


// LG
@media screen and (min-width: $min-lg) {
  .article__tags {
    ul {
      li {
        a {
          font-size: 1.5rem; } } } } }

